import styled from "styled-components";

export const NavWrapper = styled.div`
    width: auto;
    padding: 12.5px 28px;
    background: rgb(49,166,208);
    background: -moz-linear-gradient(90deg, rgba(49,166,208,1) 0%, rgba(7,37,50,1) 86%);
    background: -webkit-linear-gradient(90deg, rgba(49,166,208,1) 0%, rgba(7,37,50,1) 86%);
    background: linear-gradient(90deg, rgba(49,166,208,1) 0%, rgba(7,37,50,1) 86%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#31a6d0",endColorstr="#072532",GradientType=1);
`

export const NavLogo = styled.img`
    width: 100%;
    max-width: 72px;
    height: 55px;
`