import styled from "styled-components";

export const FooterWrapper = styled.div`
    width: auto;
    max-width: 1920px;
    padding: 100px 130px 10px 130px;

    a {
        text-decoration: underline;
        color: white;
    }

    p {
        margin-bottom: 45px;
        font-size: 16px;
    }

    @media screen and (max-width: 1280px){
        padding: 90px 50px 10px 50px;
    }
    
    @media screen and (max-width: 475px){
        padding: 45px 25px 10px 25px;
    }
`

export const FooterLogo = styled.img`
    width: 100%;
    max-width: 78px;
    height: 60px;
    margin-bottom: 18px;
`

export const FooterLine = styled.img`
    width: 100%;
    max-width: 1920px;
    margin-bottom: 45px;

    @media screen and (max-width: 1280px){
        max-width: 100%;
    }
`

export const FooterInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 765px){
        p {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 650px){
        flex-direction: column;
        align-items: flex-start;
    }
`