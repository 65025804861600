import React from "react";
import { NavLogo, NavWrapper } from "./style";
import Logo from '../../assets/images/logo-dx-labs.png';

function Navbar() {
    return (
        <NavWrapper>
            <NavLogo src={Logo} />
        </NavWrapper>
    );
}

export default Navbar;
