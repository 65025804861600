import styled from "styled-components";

export const LandingWrapper = styled.section`
    width: auto;
    background: rgb(23,43,48);
    background: -moz-linear-gradient(90deg, rgba(23,43,48,1) 58%, rgba(16,21,23,1) 98%);
    background: -webkit-linear-gradient(90deg, rgba(23,43,48,1) 58%, rgba(16,21,23,1) 98%);
    background: linear-gradient(90deg, rgba(23,43,48,1) 58%, rgba(16,21,23,1) 98%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#172b30",endColorstr="#101517",GradientType=1);
    position: relative;
`

export const LandingContainer = styled.div`
    max-width: 1440px;
    padding: 180px 0px 10px 130px;

    @media screen and (max-width: 1280px){
        padding: 90px 50px 10px 50px;
    }

    @media screen and (max-width: 475px){
        padding: 45px 25px 10px 25px;
    }
`

export const Tonic3DxLabs = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-start;
    max-width: 720px;
    position: relative;

    max-width: 1100px;

    @media screen and (max-width: 1800px){
        max-width: 720px;
    }
`

export const BgImage = styled.img`
    position: absolute;
    top: 115px;
    right: 0;

    @media screen and (max-width: 1366px){
        max-width: 400px;
    }

    @media screen and (max-width: 765px){
        max-width: 250px;
    }

    @media screen and (max-width: 475px){
        max-width: 200px;
    }
`

export const ColoredLine = styled.img`
    width: 100%;
    margin-top: 56px;
    bottom: 0;
    margin-left: -150px;
    max-width: 900px;

    @media screen and (max-width: 1800px){
        max-width: 565px;
    }

    @media screen and (max-width: 475px){
        margin-top: 28px;
    }
`

export const XRLabs = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 60px;
    max-width: 1100px;

    @media screen and (max-width: 475px){
        margin-top: 35px;
    }

    @media screen and (max-width: 1800px){
        max-width: 900px;
    }
`

export const XRLabsLogo = styled.img`
    width: 100%;
    max-width: 125px;
    height: 95px;
`

export const Purpose = styled(XRLabs)`
    max-width: 930px;
`

export const PurposePratices = styled.div`
    p {
        padding: 5px;
    }
`

export const Approach = styled(Purpose)`
    
`

export const ApproachListWrapper = styled.div`
    display: flex;
    column-gap: 30px;
    margin-top: 75px;

    @media screen and (max-width: 765px){
        flex-direction: column;
    }

    @media screen and (max-width: 475px){
        margin-top: 40px;
    }
`

export const ApproachListLeft = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-width: 575px;
    list-style: none;
`

export const ApproachListRight = styled(ApproachListLeft)`
`

export const ApproachListItem = styled.li`
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    span {
        font-weight: 500;
    }

    :before {
        content: "•"; 
        color: white;
        margin-top: 5px;
    }
`


export const ARms = styled(Approach)`
    
`