import React from "react";
import Footer from "./components/footer";
import Landing from "./components/landing";
import Navbar from "./components/navbar";
import './style/global.scss';

function App() {
  return (
    <>
      <Navbar />
      <Landing />
    </>
  );
}

export default App;
