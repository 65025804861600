import React from "react";
import { FooterInfo, FooterLine, FooterLogo, FooterWrapper } from "./style";
import Logo from '../../assets/images/logo-footer.png';
import { Paragraph } from "../typography/typography";
import Line from '../../assets/images/divider-footer.png';

function Footer() {
    return (
        <FooterWrapper>
            <FooterLogo src={Logo} />
            <Paragraph>DX Labs is the R&D team at <a href="https://tonic3.com/">Tonic3</a></Paragraph>
            <FooterLine src={Line} />
            <FooterInfo>
                <Paragraph>DALLAS | BUENOS AIRES | SÃO PAULO</Paragraph>
                <Paragraph>2022 COPYRIGHT. ALL RIGHTS RESERVED</Paragraph>
            </FooterInfo>
        </FooterWrapper>
    );
}

export default Footer;
