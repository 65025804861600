import React from "react";
import { Caption, Paragraph, Subtitle, Title } from "../typography/typography";
import { Approach, ApproachListItem, ApproachListLeft, ApproachListWrapper, ApproachListRight, ARms, ColoredLine, LandingContainer, LandingWrapper, Purpose, PurposePratices, Tonic3DxLabs, XRLabs, XRLabsLogo, BgImage } from "./style";
import Line from '../../assets/images/divider-top.png';
import XRLogo from '../../assets/images/logo-xr-labs.png';
import TonicImage from '../../assets/images/background-logo.png';
import Footer from "../footer";
import { Fade, Slide } from "react-awesome-reveal";


function Landing() {
    return (
        <LandingWrapper>
            <Fade delay={400} triggerOnce>
                <BgImage src={TonicImage} />
            </Fade>
            <LandingContainer>
                <Tonic3DxLabs>
                    <Caption>Tonic3 <span>DX LABs</span></Caption>
                    <Subtitle>We investigate the theoretical, move it to the practical and envision near future implementations.</Subtitle>
                    <Paragraph>DX Labs is the Research division of Tonic3. It seeks to publish methods, practices, and research to further the Digital Experience Industry through partnerships with academics and practitioners who are leaders in their respective fields.</Paragraph>
                    <Slide triggerOnce>
                        <ColoredLine src={Line} />
                    </Slide>
                </Tonic3DxLabs>
                <XRLabs>
                    <XRLabsLogo src={XRLogo} />
                    <Title>XR LABS</Title>
                    <Paragraph>XR Labs is a specialized research and publishing team dedicated to contributing thought leadership to the Extended Reality industry to recognize and establish XR best practices. From XR research methodology to neurological and physiological impacts to Design & Code processes that help deliver XR experiences in the real world, XR Labs is about building and sharing a knowledge base that will help all who want to envision our XR future as one that helps humans connect digitally.</Paragraph>
                </XRLabs>
                <Purpose>
                    <Title>PURPOSE</Title>
                    <Paragraph>Contribute to the XR industry in ways that help recognize and establish XR best practices.</Paragraph>
                    <PurposePratices>
                        <Paragraph>1. From XR research methodology to neurological & physiological impacts to Design & Code processes that help deliver XR experiences in the real world.</Paragraph>
                        <Paragraph>2. Today, standard practices for UX exist for the flat design world, but not for XR. While there are pockets of good work being done inside of walled gardens, the XR lab is about building and sharing a knowledge base that will help all who want to envision our XR future as one that helps humans connect digitally.</Paragraph>
                        <Paragraph>3. Tonic3 exists to humanize digital connections and the XR lab creates a space to question, learn, and experiment outside of the constraints of normal projects.</Paragraph>
                    </PurposePratices>
                </Purpose>
                <Approach>
                    <Title>APPROACH</Title>
                    <Paragraph>As promising practices, technologies, or methodologies emerge the lab will socialize them in the industry.</Paragraph>
                    <Paragraph>Core Lab Themes</Paragraph>
                    <Paragraph>Understanding the Biological Effects of Virtual Reality: <br /> VR is an alternate reality that has profound implications to cognitive, physiological, and emotional processes that humans have never before experienced on a mass scale. In a very real sense, our industry is conducting a live experiment on the human race. Responsible thought leaders need to get in front of this wave as much as possible to steer the course of VR experience development in ways that are responsible, ethical, and promote healthy human development. As a Tech industry, we largely failed with the Social Media wave, we need to learn from our mistakes and be much more responsible with VR.</Paragraph>
                </Approach>
                <ApproachListWrapper>
                    <ApproachListLeft>
                        <ApproachListItem><Paragraph><span>Neurological Responses</span> <br /> We are exploring brain activity during VR. Given that people are very different, are there brain-wave markers that indicate important VR related states (immersion, recall, comprehension, etc.)?</Paragraph></ApproachListItem>
                        <ApproachListItem><Paragraph><span>Experience Persistence</span> <br /> One particular focus of exploration is how the human mind and body reacts to persistence of AR/VR experiences.</Paragraph></ApproachListItem>
                        <ApproachListItem><Paragraph><span>Cognitive Recall</span> <br /> Understanding cognitive recall based upon immersive indications, and augmented cues, and changes to an end experience. creates a space to question, learn, and experiment outside of the constraints of normal projects.</Paragraph></ApproachListItem>
                    </ApproachListLeft>
                    <ApproachListRight>
                        <ApproachListItem><Paragraph><span>Physiological Effects</span> <br /> We are exploring the intersection of VR and physical well-being. We are working toward measuring and predicting motion sickness, headaches, and other VR-induced physiological side effects. As a second stage in this vein, we seek to be able to identify VR experience construction elements (both technical and non-technical) that drive physical health responses to VR exposure.</Paragraph></ApproachListItem>
                        <ApproachListItem><Paragraph><span>Emotional Activity</span> <br /> How can UX research move beyond a user’s self-reported emotional status to quantifiable bio measurements?</Paragraph></ApproachListItem>
                    </ApproachListRight>
                </ApproachListWrapper>
                <ARms>
                    <Title>ARms</Title>
                    <Paragraph>ARms is an Augmented Reality management system. Its purpose is to allow people with little or no technical expertise to create, customize, publish and monetize Augmented Reality content via app, web, and other interfaces.</Paragraph>
                    <Paragraph>Want to learn more about what we do? <br /> Drop us a note at info@tonic3.com</Paragraph>
                </ARms>
            </LandingContainer>
            <Footer />
        </LandingWrapper>
    );
}

export default Landing;
