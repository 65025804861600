import styled from "styled-components";

export const Caption = styled.h1`
    font-family: 'proxima-nova';
    font-weight: 700;
    line-height: 68.21px;
    font-size: 56px;
    color: #FFFFFF;
    text-transform: uppercase;

    span {
        color: #FF6121;
    }

    @media screen and (max-width: 765px){
        font-size: 40px;
        line-height: 50.21px;
    }

    @media screen and (max-width: 765px){
        font-size: 32px;
        line-height: 50.21px;
    }
`

export const Title = styled.h2`
    font-family: 'proxima-nova';
    font-weight: 700;
    line-height: 36.54px;
    font-size: 30px;
    color: #FF6121;

    @media screen and (max-width: 765px){
        font-size: 24px;
    }
`

export const Subtitle = styled.h3`
    font-family: 'proxima-nova';
    font-weight: 300;
    line-height: 38.98px;
    font-size: 32px;
    color: #FFFFFF;

    @media screen and (max-width: 765px){
        font-size: 18px;
        line-height: 24.98px;
    }
`

export const Paragraph = styled.p`
    font-family: 'proxima-nova';
    font-weight: 300;
    line-height: 24.36px;
    font-size: 20px;
    color: #FFFFFF;

    @media screen and (max-width: 765px){
        font-size: 16px;
        line-height: 22.98px;
    }
`

export const Link = styled(Paragraph)`
    text-decoration: underline;
`